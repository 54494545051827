import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DropMenu from "../../components/dropmenu";
import AddPackage from "../../components/Packages/add";
import DeletePackage from "../../components/Packages/delete";
import EditPackage from "../../components/Packages/edit";
import ShowHidePackages from "../../components/Packages/showHide";
import CustomTable from "../../components/table";
import { docUrl, secondUrl } from "../../utils/baseUrl";
import PdfOpen from "../../components/Packages/pdf";
import AddUnit from "./AddUnit";
import { deleteIcon, editIcon } from "../../assets/svgIcons";
import EditUnit from "./EditUnit";
import axios from "axios";
import { render } from "@testing-library/react";
import Modal from "../../components/modal";
import  Select  from "react-select";
import SelectComponent from "../../components/selectBox";
import { CircleLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";


function Posts() {
  const customStyles = {
    container: (base) => ({
      ...base,
      width: '100%',
    }),
    control: (base) => ({
      ...base,
      border: '.1px solid rgb(55, 9, 99)',
      width: '100%',
    }),
  };
  const [openAddModal, setOpenAddModal] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [packagesData, setPackagesData] = useState([]);


  const [Posts, setPosts] = useState([]);
  const [OpenPostContent, setOpenPostContent] = useState(false);
  const [NewPostsData, setNewPostsData] = useState(
    {
  text :null,
  group_id:null,
  image :null,
  date:null
    }
  );

  const [UpsdatePostModal, setUpsdatePostModal] = useState(false);
  const [Img, setImg] = useState();

  




  const[RowData,setRowData]=useState([])
  const[Groups,setGroups]=useState([])

  

  const [pdfOpen, setPdfOpen] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState("");

  const {id}=useParams()
  console.log(id)

  const columns = [
    {
      key: "id",
      title: "*",
      dataIndex: "post_id",
      search: true,
    },
    {
      key: "image",
      title: "post image",
      dataIndex: "actions",
      render: (text, row) => (
        <>
        <img src={row.image} alt="" style={{width:"100px",height:"100px"}} />
        </>
      ),

    },
    
    {
      key: "text",
      title: "post content",
      dataIndex: "text",
      render: (text, row) => (
        <>
        <button className="btn btn-success"
        onClick={()=>{
          setOpenPostContent(true)
          setRowData(row)
        }}
        >view post</button>
        </>
      ),
    },
    {
      key: "Actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <>
        <button
              className="btn btn-info"
              onClick={() => {
                setRowData(row);
                setUpsdatePostModal(true)
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              update
            </button>
         <button
              className="btn btn-danger"
              onClick={() => {
                setRowData(row);
  setOpenDeleteModal(true)
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              {deleteIcon}
            </button>
            
        </>
      ),
    },
  ];

  const getPosts = async () => {
  await axios.get("https://camp-coding.online/kidzania/admin/select_posts.php")
  .then((res) => {
    console.log(res);

    if (res.data.status == "success") {
      setPosts([...res.data.message]);
    } else if (res.data.status == "error") {
      toast.error(res.data.message);
    } else {
      toast.error("someThing went wrong");
    }
  })
  .catch((e) => console.log(e));
}

  useEffect(() => {
    getPosts();
  }, []);

  
  const getGroubs = async () => {
    axios
      .get("https://camp-coding.online/kidzania/admin/select_group.php")
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setGroups([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getGroubs();
  }, []);

  let group_options = Groups.map((group) => {
    return { label: group.group_name, value: group.group_id };
  });



  const AddNewPost = async () => {

    setLoading(true)

    const formData = new FormData();
    formData.append('image', NewPostsData.image);
    let ImgUrl;

   await axios.post("https://camp-coding.online/kidzania/admin/image_uplouder.php" , formData)
   .then(respon=>{
    if (respon.data.status == "success") {
      toast.success(respon.data.message)
      ImgUrl=respon.data.message
    } else if (respon.data.status == "error") {
      toast.error(respon.data.message);
    } else {
      toast.error("someThing went wrong");
    }
  }).finally(()=>{


  })
  .catch((e) => console.log(e));




    let dataSend={
      text :NewPostsData.text,
      group_id:NewPostsData.group_id,
      image :ImgUrl,
      date:NewPostsData.date
        }

         console.log(dataSend);
        
    axios
      .post("https://camp-coding.online/kidzania/admin/add_post.php" , JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          toast.success(res.data.message);
          setOpenAddModal(false)
          getPosts()
          
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).finally(()=>{
    setLoading(false)

      })
      .catch((e) => console.log(e));
  };

  const UpdatePost = async () => {

    setLoading(true)

    if(Img){
      const formData = new FormData();
      formData.append('image', Img);

  
     await axios.post("https://camp-coding.online/kidzania/admin/image_uplouder.php" , formData)
     .then(respon=>{
      if (respon.data.status == "success") {
        toast.success(respon.data.message)
        setRowData(
          {
            ...RowData,
            image:respon.data.message
          }
        )
      } else if (respon.data.status == "error") {
        toast.error(respon.data.message);
      } else {
        toast.error("someThing went wrong");
      }
    }).finally(()=>{
  
  
    })
    .catch((e) => console.log(e));
  
    }
   



    let dataSend={
      text :RowData.text,
      group_id:RowData.group_id,
      image :RowData.image,
      date:RowData.date,
      post_id:RowData.post_id,

        }

         console.log(dataSend);
        
    axios
      .post("https://camp-coding.online/kidzania/admin/update_post.php" , JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          toast.success(res.data.message);
          setUpsdatePostModal(false)
          getPosts()
          
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).finally(()=>{
    setLoading(false)

      })
      .catch((e) => console.log(e));
  };

  const deletPost = async () => {
    setLoading(true)
    let dataSend={
      post_id:RowData.post_id
        }
    axios
      .post("https://camp-coding.online/kidzania/admin/delete_post.php" , JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          toast.success(res.data.message);
          setOpenDeleteModal(false)
          getPosts()
          
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).finally(()=>{
    setLoading(false)

      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="packages">
      <div className="tablePageHeader">
        <h1 className="pageTitle">posts</h1>
        <button
          className="btn btn-success"
          style={{
            backgroundColor:" #3c2365"
  
            }} 
          onClick={() => {
            setOpenAddModal(true);
          }}
        >
         add post
        </button>
      </div>
      <CustomTable dataSource={Posts} columns={columns} />
      <PdfOpen
        // getFunction={getUnits}
        openModal={pdfOpen}
        setOpenModal={setPdfOpen}
      />

<Modal
        close={setOpenAddModal}
        footer={false}
        title={"Post"}
        visible={openAddModal}
      >
        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="text"
            placeholder="title.."
            onChange={(e) => {
              setNewPostsData(
                {
                  ...NewPostsData,
                  text:e.target.value
                }
              )
            }}
          />
        </span>
        {/* <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="text"
            placeholder="description.."
            onChange={(e) => {
            
            }}
          />
        </span> */}
        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="date"
            placeholder="date.."
            onChange={(e) => {
              setNewPostsData(
                {
                  ...NewPostsData,
                  date:e.target.value
                }
              )
            }}
          />
        </span>
        <span className="data_continer">
        <Select
  styles={customStyles}
  options={group_options}
  placeholder="select group"
  onChange={(e) => {
    setNewPostsData(
      {
        ...NewPostsData,
        group_id:e.value
      }
    )
  }}
/>
        </span>

        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="file"
            placeholder="imges.."
            onChange={(e) => {
              setNewPostsData(
                {
                  ...NewPostsData,
                  image:e.target.files[0]
                }
              )
            }}
          />
        </span>
        <button className="btn btn-success" style={{float:"right"}} onClick={AddNewPost}>
          {loading ? <CircleLoader color="white" size={30}/> : "Add"}
        </button>
      </Modal>



<Modal
        close={setOpenPostContent}
        footer={false}
        title={"Post"}
        visible={OpenPostContent}
      >
        <p>{RowData.text}</p>
      </Modal>

      
      <Modal
      close={setOpenDeleteModal}
      footer={false}
      title={"Delete Year"}
      visible={openDeleteModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this Post?</h3>
        <p className="warning-message">
        This action cannot be undone. Please confirm that you want to delete
        the following:
        </p>
        <div className="year-details">
          <strong>Post</strong>
        </div>
       
   
        
              <button
                type="button"
                className="btn btn-danger"
                onClick={deletPost}
              >
                {loading ? <CircleLoader color="white" size={30}/> : "delete"}
              </button>
             
            
        
        
        
      </div>
    </Modal>

    <Modal
        close={setUpsdatePostModal}
        footer={false}
        title={"Edit Post"}
        visible={UpsdatePostModal}
      >
        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="textarea"
            placeholder="title.."
            defaultValue={RowData.text}
            onChange={(e) => {
              setRowData(
                {
                  ...RowData,
                  text:e.target.value
                }
              )
            }}
          />
        </span>
        {/* <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="text"
            placeholder="description.."
            onChange={(e) => {
            
            }}
          />
        </span> */}
        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="date"
            placeholder="date.."
            defaultValue={RowData.date}

            onChange={(e) => {
              setRowData(
                {
                  ...RowData,
                  date:e.target.value
                }
              )
            }}
          />
        </span>
        <span className="data_continer">
        <Select
  styles={customStyles}
  options={group_options}
  

  placeholder="select group"
  onChange={(e) => {
    setRowData(
      {
        ...RowData,
        group_id:e.value
      }
    )
  }}
/>
        </span>

        <img src={RowData.image} alt="" style={{width:"80px",height:"80px"}} />

        <span className="data_continer">
          <input
          style={{
            border:".1px solid rgb(55, 9, 99)"
          }}
            type="file"
            placeholder="imges.."
            onChange={(e) => {
              setImg(e.target.files[0])
            }}
          />
        </span>
        <button className="btn btn-success" style={{float:"right"}} onClick={UpdatePost}>
          {loading ? <CircleLoader color="white" size={30}/> : "Add"}
        </button>
      </Modal>
      <Toaster
  position="top-center"
  reverseOrder={false}
/>

    </div>
  );
}

export default Posts;
