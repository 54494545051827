// import React, { useEffect, useState } from "react";
// import Modal from "../../../components/modal";
// import axios from "axios";
// import { Toaster } from "react-hot-toast";
// import { toast } from "react-hot-toast";

// const AccordionComponents = ({ reports, monthIdReport, StudentId }) => {
//   const [statusMessage, setStatusMessage] = useState('');
//   const [statusClass, setStatusClass] = useState('');
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [AddReport, setAddReport] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [RowData, setRowData] = useState([]);
//   const [audioModal, setAudioModal] = useState(false);
//   const [imgModal, setImgModal] = useState(false);
//   const [audioFile, setAudioFile] = useState(null);
//   const [audios, setAudios] = useState([]);
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [uploadedAudios, setUploadedAudios] = useState([]);
//   const [imgFile, setImgFile] = useState(null);
//   const [attachementData, setAttachmentData] = useState({
//     audio: "",
//     image: "",
//   })

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };
//   console.log(StudentId);

//   const handelAddReport = async () => {
//     setLoading(true);
//     let dataSend = {
//       sub_report_id: RowData.sub_report_id,
//       action: RowData.action,
//       month_id: monthIdReport,
//       student_id: StudentId.student_id,
//       sub_data_id: RowData.sub_data_id,
//     };

//     return console.log(dataSend, RowData);
//     await axios
//       .post("", JSON.stringify(dataSend))
//       .then((res) => {
//         if (res.data.status == "success") {
//           toast.success(res.data.message);
//         } else if (res.data.status == "error") {
//           toast.error(res.data.message);
//         } else {
//           toast.error("someThing went wrong");
//         }
//       })
//       .finally(() => {
//         setLoading(false);
//         setAddReport(false);
//       })
//       .catch((e) => console.log(e));
//   };

//   async function handleAddImage(e) {
//     e.preventDefault();
//     const uploads = await Promise.all(
//       uploadedImages.map(async item =>
//         await uploadImage(item)
//       )
//     )
//     console.log(uploads?.map(item=>item?.data?.message)?.join("//CAMP//"))
//   }

//   async function handleAddAudio(e) {
//     e.preventDefault();
//     const uploads = await Promise.all(
//       uploadedAudios.map(async item =>
//         await uploadAudio(item)
//       )
//     )
//     console.log(uploads.map(item => item))
//   }


//   async function uploadImage(image) {
//     if (!image) return
//     const formData = new FormData();
//     formData.append("image", image)

//     const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/image_uplouder.php", formData)
//     return uploaded
//   }


//   async function uploadAudio(audio) {
//     if(!audio)  return
//     const formData = new FormData()
//     formData.append("file_attachment",formData)
    

//     const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/upload_attachment.php",formData) 
//     return uploaded
//   }

//   return (
//     <div className="accordion">
//       {reports.map((report, index) => (
//         <div
//           key={index}
//           className="accordion-item"
//           style={{
//             width: "90%",
//             backgroundColor: "blueviolet",
//             margin: "15px auto",
//             display: "block",
//             textAlign: "center",
//             fontWeight: "700",
//             padding: "15px 0",
//             borderRadius: "5px",
//             cursor: "pointer",
//             color: "rgb(255, 208, 0)",
//           }}
//         >
//           <p
//             // className="month"
//             onClick={() => {
//               handleToggle(index);
//               console.log(report.sub_data);
//             }}
//             style={{
//               margin: "0px",
//               padding: "0px",
//             }}
//           >
//             <img
//               src={report.image}
//               alt=""
//               style={{ width: "20px", height: "20px", marginRight: "10px" }}
//             />
//             {report.name}
//           </p>
//           {activeIndex === index && (
//             <div
//               className="accordion-content"
//               style={{
//                 padding: "10px",
//                 background: "#e2e2e2",
//                 border: "1px solid #ddd",
//                 borderTop: "none",
//                 display: "flex",
//                 flexWrap: "wrap",
//               }}
//             >
//               {report &&
//                 Array.isArray(report.sub_data) &&
//                 report.sub_data.length > 0 ? (
//                 report.sub_data.map((data, index) => (
//                   <span
//                     key={index}
//                     onClick={() => {
//                       setAddReport(true);
//                       setRowData(data);
//                     }}
//                     style={{
//                       margin: "10px 15px",
//                       backgroundColor: "rgb(55, 9, 99)",
//                       color: "rgb(255, 208, 0)",
//                       padding: "10px 30px",
//                       borderRadius: "10px",
//                     }}
//                   >
//                     <p style={{ borderBottom: ".1px solid  rgb(255, 208, 0)" }}>
//                       {data.name}
//                     </p>

//                     <p>{data?.name == "Attention" && <div className="d-flex gap-2">
//                       <button className="btn btn-success" onClick={() => setAudioModal(true)}>Audio</button>
//                       <button className="btn btn-primary" onClick={() => setImgModal(true)}>Images</button>
//                     </div>} </p>
//                     {data.action && data.action.length > 0 ? (
//                       <div>
//                         <p>{data.action}</p>

//                       </div>

//                     ) : (
//                       <p style={{ color: "white" }}>there is no report</p>
//                     )}
//                   </span>
//                 ))
//               ) : (
//                 <p>No data available.</p>
//               )}
//             </div>
//           )}
//         </div>
//       ))}

//       {!audioModal && imgModal && <Modal close={setImgModal} visible={imgModal} title="Add Image" footer={false}>
//         <div style={{ fontFamily: 'Arial, sans-serif', margin: '50px' }}>
//           <div style={{ maxWidth: '400px', margin: 'auto' }}>
//             <h2>Upload Image</h2>
//             <form id="audioForm" onSubmit={handleAddImage} encType="multipart/form-data">
//               <input type="file" onChange={(e) => setUploadedImages((prev) => [...prev, e.target.files[0]])} name="file_attachment" accept="image/*" required />
//               <button type="submit" style={{
//                 padding: '10px 20px',
//                 backgroundColor: '#4CAF50',
//                 color: 'white',
//                 border: 'none',
//                 cursor: 'pointer'
//               }}>
//                 Upload
//               </button>
//             </form>
//             {/* <div id="status" className={`status ${statusClass}`} style={{
//                     marginTop: '20px',
//                     color: statusClass === 'success' ? 'green' : statusClass === 'error' ? 'red' : 'black'
//                 }}>
//                     {statusMessage}
//                 </div> */}
//           </div>
//         </div>
//       </Modal>}

//       {audioModal && !imgModal && <Modal close={setAudioModal} visible={audioModal} title="Add Audio" footer={false}>
//         <div style={{ fontFamily: 'Arial, sans-serif', margin: '50px' }}>
//           <div style={{ maxWidth: '400px', margin: 'auto' }}>
//             <h2>Upload Audio File</h2>
//             <form id="audioForm" onSubmit={handleAddAudio} encType="multipart/form-data">
//               <input type="file" onChange={(e) => setUploadedAudios((prev) => [...prev, e.target.files[0]])} name="file_attachment" accept="audio/*" required />
//               <button type="submit" style={{
//                 padding: '10px 20px',
//                 backgroundColor: '#4CAF50',
//                 color: 'white',
//                 border: 'none',
//                 cursor: 'pointer'
//               }}>
//                 Upload
//               </button>
//             </form>
//             <div id="status" className={`status ${statusClass}`} style={{
//               marginTop: '20px',
//               color: statusClass === 'success' ? 'green' : statusClass === 'error' ? 'red' : 'black'
//             }}>
//               {statusMessage}
//             </div>
//           </div>
//         </div>
//       </Modal>}

//       {!audioModal && !imgModal && <Modal
//         close={setAddReport}
//         footer={false}
//         title={RowData.name}
//         visible={AddReport}
//       >
//         <span
//           style={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             flexWrap: "wrap",
//           }}
//         >
//           <div
//             className="radios"
//             style={{ width: "200px", display: "flex", alignItems: "center" }}
//           >
//             <input
//               type="radio"
//               value={"Excellent"}
//               name="allergy"
//               style={{
//                 padding: "0",
//                 borderRadius: "0px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               onChange={(e) => {
//                 setRowData({
//                   ...RowData,
//                   action: e.target.value,
//                 });
//               }}
//             />
//             <p
//               style={{
//                 padding: "0",
//                 margin: "0",
//                 color: "green",
//               }}
//             >
//               Excellent
//             </p>
//           </div>
//           <div
//             className="radios"
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <input
//               type="radio"
//               value={"Very Good"}
//               name="allergy"
//               style={{
//                 padding: "0",
//                 borderRadius: "0px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               onChange={(e) => {
//                 setRowData({
//                   ...RowData,
//                   action: e.target.value,
//                 });
//               }}
//             />
//             <p
//               style={{
//                 padding: "0",
//                 margin: "0",
//                 color: "blue",
//               }}
//             >
//               Very good
//             </p>
//           </div>
//           <div
//             className="radios"
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <input
//               type="radio"
//               value={"Good"}
//               name="allergy"
//               style={{
//                 padding: "0",
//                 borderRadius: "0px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               onChange={(e) => {
//                 setRowData({
//                   ...RowData,
//                   action: e.target.value,
//                 });
//               }}
//             />
//             <p
//               style={{
//                 padding: "0",
//                 margin: "0",
//                 color: "#ffc107",
//               }}
//             >
//               {" "}
//               good
//             </p>
//           </div>
//           <div
//             className="radios"
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <input
//               type="radio"
//               value={"Fair"}
//               name="allergy"
//               style={{
//                 padding: "0",
//                 borderRadius: "0px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               onChange={(e) => {
//                 setRowData({
//                   ...RowData,
//                   action: e.target.value,
//                 });
//               }}
//             />
//             <p
//               style={{
//                 padding: "0",
//                 margin: "0",
//                 color: "#ffc107",
//               }}
//             >
//               {" "}
//               Fair
//             </p>
//           </div>
//           <div
//             className="radios"
//             style={{ display: "flex", alignItems: "center" }}
//           >
//             <input
//               type="radio"
//               value={"Poor"}
//               name="allergy"
//               style={{
//                 padding: "0",
//                 borderRadius: "0px",
//                 width: "20px",
//                 height: "20px",
//               }}
//               onChange={(e) => {
//                 setRowData({
//                   ...RowData,
//                   action: e.target.value,
//                 });
//               }}
//             />
//             <p
//               style={{
//                 padding: "0",
//                 margin: "0",
//                 color: "red",
//               }}
//             >
//               {" "}
//               Poor
//             </p>
//           </div>
//         </span>

//         <div
//           className="radios"
//           style={{ display: "flex", alignItems: "center", width: "100%" }}
//         >
//           <textarea
//             name=""
//             id=""
//             cols="15"
//             rows="5"
//             style={{ margin: "10px 0", width: "100%" }}
//             onChange={(e) => {
//               setRowData({
//                 ...RowData,
//                 note: e.target.value,
//               });
//             }}
//           ></textarea>
//         </div>

//         <button
//           className="btn btn-success"
//           onClick={() => {
//             // console.log(RowData);
//             handelAddReport();
//           }}
//         >
//           Add{" "}
//         </button>
//       </Modal>}

//       <Toaster position="top-center" reverseOrder={false} />
//     </div>
//   );
// };

// export default AccordionComponents;


import React, { useEffect, useState } from "react";
import Modal from "../../../components/modal";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";

const AccordionComponents = ({ reports, monthIdReport, StudentId }) => {
//   const [statusMessage, setStatusMessage] = useState('');
//   const [statusClass, setStatusClass] = useState('');
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [AddReport, setAddReport] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [RowData, setRowData] = useState([]);
//   const [audioModal, setAudioModal] = useState(false);
//   const [imgModal, setImgModal] = useState(false);
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [uploadedAudios, setUploadedAudios] = useState([]);
//   const [updatedAttachement , setUpdatedAttachement] = useState({
//     imgs_attachment : "",
//     audios_attachment: "",
//   })

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const handelAddReport = async () => {
//     setLoading(true);
//     let dataSend = {
//       sub_report_id: RowData.sub_report_id,
//       action: RowData.action,
//       month_id: monthIdReport,
//       student_id: StudentId.student_id,
//       sub_data_id: RowData.sub_data_id,
//     };

//     try {
//       const res = await axios.post("", JSON.stringify(dataSend));
//       if (res.data.status === "success") {
//         toast.success(res.data.message);
//       } else if (res.data.status === "error") {
//         toast.error(res.data.message);
//       } else {
//         toast.error("Something went wrong");
//       }
//     } catch (e) {
//       console.error(e);
//     } finally {
//       setLoading(false);
//       setAddReport(false);
//     }
//   };

//   async function handleAddImage(e) {
//     e.preventDefault();
//     const uploads = await Promise.all(
//       uploadedImages.map(async item => await uploadImage(item))
//     );
//     console.log(uploads.map(item => item?.data?.status =="success" ? toast.success("image uploaded success") : toast.error("there's an error")))
//     setUpdatedAttachement({...updatedAttachement , imgs_attachment : uploads.map(item => item?.data?.message)?.join("//CAMP//") , audios_attachment : ""})
//   }

//   async function handleAddAudio(e) {
//     e.preventDefault();
//     const uploads = await Promise.all(
//       uploadedAudios.map(async item => await uploadAudio(item))
//     );
//     setUpdatedAttachement({...updatedAttachement , audios_attachment : uploads.map(item => item?.data?.link)?.join("//CAMP//") , imgs_attachment : ""})
   
//   }

//   async function uploadImage(image) {
//     if (!image) return;
//     const formData = new FormData();
//     formData.append("image", image);

//     const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/image_uplouder.php", formData);
//     setImgModal(false)
//     return uploaded;
//   }

//   async function uploadAudio(audio) {
//     if (!audio) return;
//     const formData = new FormData();
//     formData.append("file_attachment", audio);
 
//     const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/upload_attachment.php", formData);
//     setAudioModal(false)
//     return uploaded;
//   }

//   useEffect(() => { 
//     console.log(updatedAttachement)


//   } , [updatedAttachement])


//   function handleAddAudioAndImg() {

//     const myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");

// const raw = JSON.stringify(updatedAttachement);

// const requestOptions = {
//   method: "POST",
//   headers: myHeaders,
//   body: raw,
//   redirect: "follow"
// };

// fetch("https://camp-coding.online/kidzania/admin/update_attachments.php", requestOptions)
//   .then((response) => {
//     if(response?.status == "success")
//     toast.success("Success")
//   else {
//     toast.error("There's an error")
//   }
//   })
//   .then((result) => console.log(result))
//   .catch((error) => console.error(error));
//   }


//   useEffect(() => {
//   if(updatedAttachement?.audios_attachment =="" && updatedAttachement?.imgs_attachment=="") 
//     return;
//     handleAddAudioAndImg()
//   }, [updatedAttachement]);
 


const [statusMessage, setStatusMessage] = useState('');
const [statusClass, setStatusClass] = useState('');
const [activeIndex, setActiveIndex] = useState(null);
const [AddReport, setAddReport] = useState(false);
const [loading, setLoading] = useState(false);
const [RowData, setRowData] = useState([]);
const [audioModal, setAudioModal] = useState(false);
const [imgModal, setImgModal] = useState(false);
const [uploadedImages, setUploadedImages] = useState([]);
const [uploadedAudios, setUploadedAudios] = useState([]);
const [updatedAttachement, setUpdatedAttachement] = useState({
  imgs_attachment: "",
  audios_attachment: "",
});

const handleToggle = (index) => {
  setActiveIndex(activeIndex === index ? null : index);
};

const handelAddReport = async () => {
  setLoading(true);
  const dataSend = {
    sub_report_id: RowData.sub_report_id,
    action: RowData.action,
    month_id: monthIdReport,
    student_id: StudentId.student_id,
    sub_data_id: RowData.sub_data_id,
  };

  try {
    const res = await axios.post("", JSON.stringify(dataSend));
    if (res.data.status === "success") {
      toast.success(res.data.message);
    } else if (res.data.status === "error") {
      toast.error(res.data.message);
    } else {
      toast.error("Something went wrong");
    }
  } catch (e) {
    console.error(e);
  } finally {
    setLoading(false);
    setAddReport(false);
  }
};

async function handleAddImage(e) {
  e.preventDefault();
  const uploads = await Promise.all(
    uploadedImages.map(async (item) => {
      const result = await uploadImage(item);
      if (result?.data?.status === "success") {
        toast.success("Image uploaded successfully");
      } else {
        toast.error("Error uploading image");
      }
      return result;
    })
  );
  setUpdatedAttachement({
    ...updatedAttachement,
    imgs_attachment: uploads.map(item => item?.data?.message)?.join("//CAMP//"),
    audios_attachment: ""
  });
}

async function handleAddAudio(e) {
  e.preventDefault();
  const uploads = await Promise.all(
    uploadedAudios.map(async (item) => await uploadAudio(item))
  );
  setUpdatedAttachement({
    ...updatedAttachement,
    audios_attachment: uploads.map(item => item?.data?.link)?.join("//CAMP//"),
    imgs_attachment: ""
  });
}

async function uploadImage(image) {
  if (!image) return;
  const formData = new FormData();
  formData.append("image", image);

  const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/image_uplouder.php", formData);
  setImgModal(false);
  return uploaded;
}

async function uploadAudio(audio) {
  if (!audio) return;
  const formData = new FormData();
  formData.append("file_attachment", audio);

  const uploaded = await axios.post("https://camp-coding.online/kidzania/admin/upload_attachment.php", formData);
  setAudioModal(false);
  return uploaded;
}

useEffect(() => { 
  console.log(updatedAttachement);
}, [updatedAttachement]);

function handleAddAudioAndImg() {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(updatedAttachement);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("https://camp-coding.online/kidzania/admin/update_attachments.php", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result?.status === "success") {
        toast.success("Upload successful");
        setImgModal(false); // Close the image modal if it's open
        setAudioModal(false); // Close the audio modal if it's open
      } else {
        toast.error("There's an error");
      }
    })
    .catch((error) => {
      console.error(error);
      toast.error("An error occurred");
    });
}

useEffect(() => {
  if (updatedAttachement?.audios_attachment === "" && updatedAttachement?.imgs_attachment === "") 
    return;
  
  handleAddAudioAndImg();
}, [updatedAttachement]);


  return (
    <div className="accordion">
      {reports.map((report, index) => (
        <div
          key={index}
          className="accordion-item"
          style={{
            width: "90%",
            backgroundColor: "blueviolet",
            margin: "15px auto",
            display: "block",
            textAlign: "center",
            fontWeight: "700",
            padding: "15px 0",
            borderRadius: "5px",
            cursor: "pointer",
            color: "rgb(255, 208, 0)",
          }}
        >
          <p
            onClick={() => handleToggle(index)}
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            <img
              src={report.image}
              alt=""
              style={{ width: "20px", height: "20px", marginRight: "10px" }}
            />
            {report.name}
          </p>
          {activeIndex === index && (
            <div
              className="accordion-content"
              style={{
                padding: "10px",
                background: "#e2e2e2",
                border: "1px solid #ddd",
                borderTop: "none",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {report?.sub_data?.length > 0 ? (
                report.sub_data.map((data, idx) => (
                  <span
                    key={idx}
                    onClick={() => {
                      setAddReport(true);
                      setRowData(data);
                    }}
                    style={{
                      margin: "10px 15px",
                      backgroundColor: "rgb(55, 9, 99)",
                      color: "rgb(255, 208, 0)",
                      padding: "10px 30px",
                      borderRadius: "10px",
                    }}
                  >
                    <p style={{ borderBottom: ".1px solid  rgb(255, 208, 0)" }}>
                      {data.name}
                    </p>

                    <p>{data?.name === "Attention" && <div className="d-flex gap-2">
                      <button className="btn btn-success" onClick={() => setAudioModal(true)}>Audio</button>
                      <button className="btn btn-primary" onClick={() => setImgModal(true)}>Images</button>
                    </div>} </p>
                    {data.action ? (
                      <div>
                        <p>{data.action}</p>
                      </div>
                    ) : (
                      <p style={{ color: "white" }}>There is no report</p>
                    )}
                  </span>
                ))
              ) : (
                <p>No data available.</p>
              )}
            </div>
          )}
        </div>
      ))}

      {!audioModal && imgModal && <Modal close={setImgModal} visible={imgModal} title="Add Image" footer={false}>
        <div style={{ margin: '50px' }}>
          <h2>Upload Image</h2>
          <form onSubmit={handleAddImage} encType="multipart/form-data">
            <input type="file" onChange={(e) => setUploadedImages((prev) => [...prev, e.target.files[0]])} accept="image/*" required />
            <button type="submit" className="btn btn-success">Upload</button>
          </form>
         
         {reports?.map(report => report?.images?.length > 0 && 
          <div className="d-flex gap-3">
          <h5>Images</h5>
           {reports.map(report => report?.images?.map(image => <img style={{width:"100px",height:"100px",borderRadius:"10px",objectFit:"cover"}} src={image}/>))}
         </div>
         )}
          
        </div>
      </Modal>}

      {audioModal && !imgModal && <Modal close={setAudioModal} visible={audioModal} title="Add Audio" footer={false}>
        <div style={{ margin: '50px' }}>
          <h2>Upload Audio File</h2>
          <form onSubmit={handleAddAudio} encType="multipart/form-data">
            <input type="file" onChange={(e) => setUploadedAudios((prev) => [...prev, e.target.files[0]])} accept="audio/*" required />
            <button type="submit" className="btn btn-success">Upload</button>
          </form>

          {reports?.map(rep => rep?.audios?.length > 0 &&  
        <div className="d-flex gap-3">
          <h5>Audios</h5>
           {reports.map(report => report?.audios?.map(aud => <audio controls style={{width:"100px",height:"100px",borderRadius:"10px",objectFit:"cover"}}>
            <source src={aud}></source>
           </audio>))}
         </div>)}
        </div>
      </Modal>}

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default AccordionComponents;
