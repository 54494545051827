import Home from "../../pages/home";
import Students from "../../pages/students";
import {
  FaHome,
  FaCalendarAlt,
  FaUsers,
  FaUserGraduate,
  FaUser,
  FaBookOpen,
  FaWolfPackBattalion,
  FaMoneyCheckAlt,
  FaMoneyBillWave,
  FaUserClock,
  FaUserAltSlash,
  FaDollarSign,
  FaCreditCard,
  FaUsersSlash,
  FaBoxOpen,
  FaRegCreditCard,
  FaFileArchive,
  FaMoneyBillAlt,
  FaPersonBooth,
  FaChalkboardTeacher,
  FaRegCalendarTimes,
} from "react-icons/fa";
import Years from "../../pages/years";
import Groups from "../../pages/groups";
import Absense from "../../pages/Absense/Absense";
import ExamQuestions from "../../pages/Absense/examQuestions";
import ExamScores from "../../pages/Absense/examScores";
import Lectures from "../../pages/DaysAbsence/DaysAbsence";
import Days from "../../pages/days";
import DaysQuiz from "../../pages/days/dayQuizzess";
import QuizQuestions from "../../pages/days/dayQuizzess/quizQuestions";
import QuizScores from "../../pages/days/dayQuizzess/quizScores";
import Videos from "../../pages/days/dayVideos";
import VideoQuiz from "../../pages/days/dayVideos/videoQuizzess";
import VideoQuizQuestions from "../../pages/days/dayVideos/videoQuizzess/quizQuestions";
import VideoQuizScores from "../../pages/days/dayVideos/videoQuizzess/quizScores";
import AbsentStudents from "../../pages/students/absent";
import Login from "../../pages/login";
import YearStudents from "../../pages/years/students";
import SubscriptionCards from "../../pages/SubscriptionCards";
import ReportMonthes from "../../pages/years/ReportMonthes/ReportMonthes";
import Teachers from "../../pages/Teachers/Teachers";
import YearGroups from "../../pages/years/YearGroups/index";
import GroupStudents from "../../pages/groupsStudents";
import GroupsQuizzes from "../../pages/years/YearGroups/exams";
import ExamGroupsScores from "../../pages/years/YearGroups/examScores";
import PausedStudents from "../../pages/students/Paused";
import CheckTransferMoney from "../../pages/transfer";
import TransferMoney from "../../pages/transfer/insertTransfer";
import SubscriptionCounts from "../../pages/packeges/subscriptionsCards";
import CheckCard from "../../pages/students/checkCard";
import PackSubscriptions from "../../pages/packeges/Subscriptions";
import Packages from "../../pages/packeges";
import LectureScores from "../../pages/DaysAbsence/LectureScores";
import VideoScores from "../../pages/DaysAbsence/VideoScores";
import Posts from "../../pages/Services/Posts";
import UnitLessons from "../../pages/UnitLessons/UnitLessons";
import LessonQuestions from "../../pages/LessonQuestions/LessonQuestions";
import QuestionBank from "../../pages/QuestionBank";
import DaysAbsence from "../../pages/DaysAbsence/DaysAbsence";
import Admins from "../../pages/students/admins";
import Test from "../../pages/Test/Test";

export const links = localStorage.getItem("moreenglishlogin")
  ? [
      {
        id: 1023,
        label: "Admins",
        route: "/Admins",
        icon: <FaUserGraduate />,
        component: Admins,
      },
      {
        id: 1,
        label: "Groups",
        route: "/years",
        icon: <FaCalendarAlt />,
        component: Years,
        subRoutes: [
          {
            route: "",
            component: Years,
          },
          {
            route: ":id/groups",
            component: YearGroups,
          },
          {
            route: ":id/groups/:group/Packages",
            component: Packages,
          },
          {
            route: ":year_id/groups/:group_id/exams",
            component: GroupsQuizzes,
          },
          {
            route: ":yearId/groups/:groupID/exams/:quiz_id/score",
            component: ExamGroupsScores,
          },

          {
            route: ":id/groups/:group/Packages/:pack/lectures/:lecture/days",
            component: Days,
          },
          {
            route: ":yearId/groups/:group/days/:id/AbsentStudents",
            component: AbsentStudents,
          },
          {
            route: ":id/groups/:group/Packages/:pack/lectures",
            component: Lectures,
          },

          {
            route: ":group_id/students",
            component: YearStudents,
          },
        ],
      },

      {
        id: 3,
        label: "students",
        route: "/students",
        icon: <FaUserGraduate />,
        component: Students,
      },
     
      {
        id: 4,
        label: "posts",
        route: "/posts",
        icon: <FaBookOpen />,
        component: Posts,
      },

      {
        id: 5,
        label: " Absence",
        route: "/DaysAbsence",
        icon: <FaFileArchive />,
        component: DaysAbsence,
      },
      {
        id: 6,
        label: " Teachers",
        route: "/Teachers",
        icon: <FaChalkboardTeacher />,
        component: Teachers,
      },
      {
        id: 7,
        label: "Report Monthes",
        route: "/Monthes",
        icon: <FaRegCalendarTimes />,
        component: ReportMonthes,
      },
      {
        id:9,
        label :"Test",
        route : "/test",
        component : Test
      }
    ]
  : [
      {
        id: 4,
        label: "Log Out",
        route: "*",
        icon: <FaUser />,
        component: Login,
        hidden: true,
      },
      
    ];
