import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
  GroupsIcon,
  closedEye,
  deleteIcon,
  editIcon,
  lectures,
  openedEye,
  students,
} from "../../assets/svgIcons";
import CustomTable from "../../components/table";
import AddYears from "../../components/years/add";
import DeleteYears from "../../components/years/delete";
import EditYears from "../../components/years/edit";
import ShowHideYears from "../../components/years/showHide";
import {baseUrl} from "../../utils/baseUrl";
import "./style.css";
import {MdSubscriptions} from "react-icons/md";
import {FaBookOpen, FaRegNewspaper} from "react-icons/fa";
import axios from "axios";
import Modal from "../../components/modal";
import { CircleLoader } from "react-spinners";
import {toast} from "react-toastify";


function Years() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [AddGroupModal, setAddGroupModal] = useState(false);
  const [NewGroupData,setNewGroupData]=useState([])
  const [RowData,setRowData]=useState([])


  const navigate = useNavigate();
  const [years, setYears] = useState(null);


  const getYears = async () => {
    await axios.get("https://camp-coding.online/kidzania/admin/select_group.php")
    .then((res) => {
      console.log(res);
      if (res.data.status == "success") {
        setYears(res.data.message)
      } else if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        toast.error("someThing went wrong");
      }
    }).catch((e) => console.log(e));
    
  };


  useEffect(() => {
    getYears();
  }, []);


  const AddYear =  () => {

    let dataSend ={
      group_name:NewGroupData?.group_name
    }

     console.log(dataSend);
    

     axios.post("https://camp-coding.online/kidzania/admin/add_group.php", JSON.stringify(dataSend))
    .then((res) => {
      console.log(res.data);
      if (res.data.status == "success") {
        toast.success(res.data.message)
        getYears()
        setAddGroupModal(false)
      } else if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        toast.error("someThing went wrong");
      }
    }).catch((e) => console.log(e));
    
  };

  const EditYear =  () => {
    setLoading(true)
    let dataSend ={
      group_id:RowData?.group_id,
      group_name:RowData?.group_name
    }

     console.log(dataSend);
    

     axios.post("https://camp-coding.online/kidzania/admin/update_group.php", JSON.stringify(dataSend))
    .then((res) => {
      console.log(res.data);
      if (res.data.status == "success") {
        toast.success(res.data.message)
      console.log(res);


      } else if (res.data.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("someThing went wrong");
      }
    }).finally(()=>{
    setLoading(false)
    setOpenEditModal(false)
    getYears()

      
    }).catch((e) => console.log(e));
    
  };

  const deleteYear =  () => {
    setLoading(true)
    let dataSend ={
      group_id:RowData?.group_id,

    }

      console.log(dataSend);
    

     axios.post("https://camp-coding.online/kidzania/admin/delete_group.php", JSON.stringify(dataSend))
    .then((res) => {
      console.log(res.data);
      if (res.data.status == "success") {
        toast.success(res.data.message)
      console.log(res);


      } else if (res.data.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("someThing went wrong");
      }
    }).finally(()=>{
    setLoading(false)
    setOpenDeleteModal(false)
    getYears()

      
    }).catch((e) => console.log(e));
    
  };


  const columns = [
    {
      key: "Generation",
      title: "#",
      dataIndex: "group_id",
      search: true,
    },
    {
      key: "group_name",
      title: "group name",
      dataIndex: "group_name",
      search: true,
    },
    {
      key: "actions",
      title: "أوامر",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <div className='actions-btns'>
                <div
              className='open-btn c-pointer text-primary'
              onClick={() => {
                setOpenEditModal(true);
                setRowData(row)
              }}
            >
              <div className='tooltip'>Edit</div>
              {editIcon}
            </div>
            <div
              className='delete-btn c-pointer text-danger'
              onClick={() => {
                setOpenDeleteModal(true)
              setRowData(row)
              }}
            >
              <div className='tooltip'>Delete</div>
              {deleteIcon}
            </div>
            {/* 

        

            <div
              className={
                row?.hidden
                  ? "showhide-btn c-pointer text-success"
                  : "showhide-btn c-pointer text-danger"
              }
              onClick={() => setOpenShowHideModal(row)}
            >
              <div className='tooltip'>{row?.hidden ? "Show" : "Hide"}</div>
              {row?.hidden ? closedEye : openedEye}
            </div> */}

            {/* <div
              className='open-btn c-pointer text-success'
              onClick={() => navigate(`${row?.key}/lectures`)}
            >
              <div className='tooltip'>Lectures</div>
              {lectures}
            </div> */}

            {/*
             */}
            <div
              className='open-btn c-pointer text-success'
              onClick={() =>{
                navigate(`${row?.group_id}/students`)
               
              }
               
              }
            >
              <div className='tooltip'>الطلاب</div>
              {students}
            </div>

           
          </div>
        );
      },
    },
  ];
  return (
    <div className='years'>
      <div className='tablePageHeader'>
        <h1 className='pageTitle'>Groups</h1>
        {/* <button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          Add Year
        </button> */}
        <button
        className="btn btn-success"
        style={{ margin: "10px 0",backgroundColor:" #3c2365" }}
        
        onClick={() => setAddGroupModal(true)}
      >
       {loading ? <CircleLoader color="white" size={30}/> : "Add group"}
      </button>
      </div>
      
      <CustomTable dataSource={years} columns={columns} />

      <AddYears openModal={openModal} setOpenModal={setOpenModal} />
      <Modal
        close={setOpenEditModal}
        footer={false}
        title={"Edit Group"}
        visible={openEditModal}
      >
            <span className="data_continer">
              
              <input
                type="text"
                style={{
                  border:"none",
                  borderBottom: ".1px solid #3c2365"
                            }}
                placeholder="group name.."
                defaultValue={RowData?.group_name}
                onChange={(e) => {
                  setRowData(
                  {
                    ...RowData,
                    group_name:e.target.value
                  }
                 )
                }}
              />
            </span>
            <button
        className="btn btn-success"
        style={{ margin: "10px 0",float:"right" }}
        onClick={EditYear}
      >
        {loading ? <CircleLoader color="white" size={30}/> : "Edit group"}
      </button>
        </Modal>
        <Modal
      close={setOpenDeleteModal}
      footer={false}
      title={"Delete Year"}
      visible={openDeleteModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this group?</h3>
        <p className="warning-message">
        This action cannot be undone. Please confirm that you want to delete
        the following:
        </p>
        <div className="year-details">
          <strong>group:</strong> {RowData?.group_name}
        </div>
       
   
        
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteYear}
              >
                حذف
              </button>
             
            
        
        
        
      </div>
    </Modal>

      <ShowHideYears
        openModal={openShowHideModal}
        setOpenModal={setOpenShowHideModal}
      />

<Modal
        close={setAddGroupModal}
        footer={false}
        title={"Add New Group"}
        visible={AddGroupModal}
      >
        
              <span className="data_continer">
              
          <input
            type="text"
            style={{
              border:"none",
              borderBottom: ".1px solid #3c2365"
                        }}
            placeholder="group name.."
            onChange={(e) => {
             setNewGroupData(
              {
                ...NewGroupData,
                group_name:e.target.value
              }
             )
            }}
          />
        </span>

        <button
          className="btn btn-success"
          style={{ float: "right" ,float:"right"}}
          onClick={AddYear}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Add"}
        </button>

        </Modal>
    </div>
  );
}

export default Years;
