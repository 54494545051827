import React, { useState } from 'react';
import { baseUrl } from '../../utils/baseUrl';

const Test = () => {
    const [statusMessage, setStatusMessage] = useState('');
    const [statusClass, setStatusClass] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target); // Collect form data
        setStatusMessage('Uploading...');
        setStatusClass('');

        try {
            // Send the form data using Fetch API
            const response = await fetch('https://camp-coding.online/kidzania/admin/upload_attachment.php', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();

            if (data.link) {
                // Successfully uploaded the file
                setStatusMessage(`Upload successful! File link: ${data.link}`);
                setStatusClass('success');
            } else if (data.error) {
                // Error during upload
                setStatusMessage(`Error: ${data.error}`);
                setStatusClass('error');
            }
        } catch (error) {
            // Handle network or other unexpected errors
            setStatusMessage(`Error: ${error.message}`);
            setStatusClass('error');
        }
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', margin: '50px' }}>
            <div style={{ maxWidth: '400px', margin: 'auto' }}>
                <h2>Upload Audio File</h2>
                <form id="audioForm" onSubmit={handleSubmit} encType="multipart/form-data">
                    <input type="file" name="file_attachment" accept="audio/*" required />
                    <button type="submit" style={{
                        padding: '10px 20px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer'
                    }}>
                        Upload
                    </button>
                </form>
                <div id="status" className={`status ${statusClass}`} style={{
                    marginTop: '20px',
                    color: statusClass === 'success' ? 'green' : statusClass === 'error' ? 'red' : 'black'
                }}>
                    {statusMessage}
                </div>
            </div>
        </div>
    );
};

export default Test;
