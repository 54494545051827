import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  days
} from "../../assets/svgIcons";
import AddLecture from "../../components/Lectures/add";
import DeleteLecture from "../../components/Lectures/delete";
import EditLecture from "../../components/Lectures/edit";
import ShowHideLectures from "../../components/Lectures/showHide";
import CustomTable from "../../components/table";
import { docUrl, secondUrl } from "../../utils/baseUrl";
import "./style.css";
import axios from "axios";
import { toast } from 'react-hot-toast';
import { Toaster } from "react-hot-toast";
import Modal from "../../components/modal";

function DaysAbsence() {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [Days, setDays] = useState([]);
  const [GroupsData, setGroupsData] = useState([]);
  const [Students, setStudents] = useState([]);
  const [AbsenceStudents, setAbsenceStudents] = useState([]);




  const [OpenGroupsModal, setOpenGroupsModal] = useState(false);
  const [OpenStudentsModal, setOpenStudentsModal] = useState(false);

  const [RowData, setRowData] = useState([]);
  const [GroupRowData, setGroupRowData] = useState([]);



  const columns = [
    {
      key: "#",
      title: "#",
      dataIndex: "day_id",
      search: true,
    },
    {
      key: "day",
      title: "day",
      dataIndex: "day",
      search: true,
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <>
          <button className="btn btn-success"
          onClick={()=>{
            setOpenGroupsModal(true)
            setRowData(row)
          }}
          >groups</button>
          </>
        );
      },
    },

  ];


  const groupCoulmn = [
    {
      key: "group_name",
      title: "group name",
      dataIndex: "group_name",
      search: true,
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <>
          <button className="btn btn-success"
          onClick={()=>{
            setOpenStudentsModal(true)
            setGroupRowData(row)
            getDayStudents()
          }}
          >students</button>
          </>
        );
      },
    },

   
  ];

  const Studentscolumns = [
    {
      key: "id",
      title: "id",
      dataIndex: "student_id",
      search: true,
    },
    {
      key: "name",
      title: "Student name",
      dataIndex: "name",
      search: true,
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <>
          <button className="btn btn-success"
          onClick={()=>{
            handelStudentAbsent(row.student_id)
          }}
          >mark as absent</button>
          </>
        );
      },
    },
  ]

  const getDays = async () => {
    await axios.get("https://camp-coding.online/kidzania/admin/select_days_absent.php")
    .then((res) => {
      console.log(res);
  
      if (res.data.status == "success") {
        setDays([...res.data.message]);
      } else if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        toast.error("someThing went wrong");
      }
    })
    .catch((e) => console.log(e));
  }
  
    useEffect(() => {
      getDays();
    }, []);

    const AddDays = async () => {
      
      await axios.post("https://camp-coding.online/kidzania/admin/create_day_absent.php")
      .then((res) => {
        console.log(res);
    
        if (res.data.status == "success") {
          toast.success(res.data.message);

          getDays()
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
    }


    const getDayStudents = async () => {
      let dataSend ={
        group_id:GroupRowData.group_id,
        day_id:RowData.day_id
    }

     console.log(dataSend);
    
    
      await axios.post("https://camp-coding.online/kidzania/admin/get_not_absent_group_student.php" , JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);
    
        if (res.data.status == "success") {
          setStudents([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).finally(()=>{
        
      })
      .catch((e) => console.log(e));
    }

    

    const getYears = async () => {
      await axios.get("https://camp-coding.online/kidzania/admin/select_group.php")
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setGroupsData(res.data.message)
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).catch((e) => console.log(e));
      
    };
  
  
    useEffect(() => {
      getYears();
    }, []);


    const handelStudentAbsent = async(RowId) =>{
      let dataSend ={
        student_id:RowId,
        day_id:RowData.day_id
    }

      console.log(dataSend);
    
    
      await axios.post("https://camp-coding.online/kidzania/admin/take_absent.php" , JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);
    
        if (res.data.status == "success") {
          toast.success(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      }).finally(()=>{
        getDayStudents()
      })
      .catch((e) => console.log(e));
    }

  return (
    <div className="lectures">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Days Absence</h1>
        <button
          className="btn btn-success"
          style={{
            backgroundColor:" #3c2365"
  
            }} 
          onClick={() => {
            AddDays()
          }}
        >
          Add day
        </button>
      </div>
      <CustomTable dataSource={Days} columns={columns} />
      




<Modal
        close={setOpenGroupsModal}
        footer={false}
        title={"groups"}
        visible={OpenGroupsModal}
      >
      <CustomTable dataSource={GroupsData} columns={groupCoulmn} />

      </Modal>

     {AbsenceStudents &&
     
     <Modal
        close={setOpenStudentsModal}
        footer={false}
        title={"Students"}
        visible={OpenStudentsModal}
      >
      <CustomTable dataSource={Students} columns={Studentscolumns} />

      </Modal>}


<Toaster
  position="top-center"
  reverseOrder={false}
/>
    </div>
    
  );
}

export default DaysAbsence;
