import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table";

import { useParams } from "react-router-dom";
import { baseUrl } from "../../../utils/baseUrl";
import {
  closedEye,
  deleteIcon,
  editIcon,
  openedEye,
  openPage,
} from "../../../assets/svgIcons";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Modal from "../../../components/modal";
import Select from "react-select";
import { Loader } from "rsuite";
import { CircleLoader } from "react-spinners";

function ReportMonthes() {
  const [NewMonth, setNewMonth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [NewMonthData, setNewMonthData] = useState({
    month: null,
  });
  const [Monthes, setMonthes] = useState(null);
  const [RowData, setRowData] = useState(null);

  const { id, type } = useParams();

  const getMonthes = async () => {
    axios
      .get("https://camp-coding.online/kidzania/admin/select_months.php")
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setMonthes([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getMonthes();
  }, []);

  const AddNewMonth = async () => {
    setLoading(true);
    console.log(NewMonthData);
    let dataSend = {
      month: NewMonthData.month,
    };
    axios
      .post(
        "https://camp-coding.online/kidzania/admin/create_month.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setNewMonth(false);
          toast.success(res.data.message);
          getMonthes();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      }, [])
      .catch((e) => console.log(e));
  };

  const deleteMonth = async () => {
    setLoading(true);

    let dataSend = {
      date_id: RowData.date_id,
    };
    axios
      .post(
        "https://camp-coding.online/kidzania/admin/delete_month.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setOpenDeleteModal(false);
          toast.success(res.data.message);
          getMonthes();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      }, [])
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      key: "#",
      title: "#",
      dataIndex: "date_id",
      search: true,
    },
    {
      key: "month",
      title: "Month",
      dataIndex: "month",
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "x",
      render: (text, row) => {
        return (
          <button
            className="btn btn-danger"
            onClick={() => {
              setRowData(row);
              setOpenDeleteModal(true);
            }}
            style={{
              margin: "0 5px",
              color: "white",
            }}
          >
            {deleteIcon}
          </button>
        );
      },
    },
  ];
  return (
    <div className="students">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Report Monthes</h1>
        <button
          className="btn btn-success"
          style={{ backgroundColor: "#3c2365" }}
          onClick={() => {
            setNewMonth(true);
          }}
        >
          Add month
        </button>
      </div>
      <CustomTable dataSource={Monthes} columns={columns} />

      <Modal
        close={setNewMonth}
        footer={false}
        title={"New month"}
        visible={NewMonth}
      >
        <span className="data_continer">
          <input
            type="month"
            onChange={(e) => {
              const date = new Date(e.target.value);
              setNewMonthData({
                ...NewMonthData,
                month: `${date.getMonth() + 1}-${date.getFullYear()}`,
              });
            }}
          />
        </span>

        <button className="btn btn-success" onClick={AddNewMonth}>
          {loading ? <CircleLoader color="white" size={30} /> : "Add"}
        </button>
      </Modal>

      <Modal
        close={setOpenDeleteModal}
        footer={false}
        title={"Delete Year"}
        visible={OpenDeleteModal}
      >
        <div className="delete-warning">
          <h3>
            Are you sure you want to delete this student from this Report Month?
          </h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following:
          </p>
          <div className="year-details">
            {/* <strong>Teacher:</strong> {RowData?.name} */}
          </div>

          <button
            type="button"
            className="btn btn-danger"
            onClick={deleteMonth}
          >
            {loading ? <CircleLoader color="white" size={30} /> : "delete"}
          </button>
        </div>
      </Modal>

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default ReportMonthes;
