import React, { useEffect, useState } from "react";
import CustomTable from "../../../src/components/table";

import { toast } from 'react-hot-toast';

import {
  closedEye,
  deleteIcon,
  editIcon,
  openedEye,
  openPage,
  questions,
  score,
} from "../../../src/assets/svgIcons";
import Select from "react-select";
import uniform from "../../../src/assets/uniform.png";
import subscription from "../../../src/assets/subscription.png";
import addFinanc from "../../../src/assets/plus.png";
import book from "../../../src/assets/books.png";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { CircleLoader } from "react-spinners";
import  Modal  from "../../components/modal";

function DaysQuiz() {
  const [OpenAddModal, setOpenAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TeachersData, setTeachersData] = useState([]);
  const [RowData, setRowData] = useState([]);
  const [GroupRowData, setGroupRowData] = useState([]);

  const [UpdateTeacherModal, setUpdateTeacherModal] = useState(false);
  const [Finance, setFinance] = useState(false);
  const [TeacherGroupsModal, setTeacherGroupsModal] = useState(false);
  const [OpenDeleteTeacherGroupModal, setOpenDeleteTeacherGroupModal] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [groups, setGroups] = useState([]);
  const [TeacherGroups, setTeacherGroups] = useState([]);
  const [AddNewTeacher, setAddNewTeacher] = useState(
    {
      name: null,
      phone: null,
      group_ids: null,
      gender: null
    }
  );


  const [NewFinance, setNewFinance] = useState(false);
  const [NewFinanceData, setNewFinanceData] = useState({
    teacher_id: null,
    price: null,
    describtion: null,
    type:null
  });
  const FinanceOptions = [
    { label: "uniform", value: "uniform" },
    { label: "subscription", value: "subscription" },
    { label: "books", value: "books" },
  ];

  const genderOptions = [
    { label: "male", value: "male" },
    { label: "female", value: "female" },
    
  ];

  const [studentsFinance, setStudentsFinance] = useState(null);

  const navigate = useNavigate();

  const Teachercolumns = [
    {
      key: "id",
      title: "id",
      dataIndex: "teacher_id",
      search: true,
    },
    {
      key: "name",
      title: "Teacher name",
      dataIndex: "name",
      search: true,
    },
    {
      key: "name",
      title: "phone",
      dataIndex: "phone",
      search: true,
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <>
          <button className="btn btn-info"
          onClick={()=>{
            setRowData(row)
            setUpdateTeacherModal(true)
          }}
          >update</button>
          <button className="btn btn-success"
          style={{
            margin:"0px 10px"
          }}
          onClick={()=>{
            setRowData(row)
            setFinance(true)
            getTeacherFinance(row.teacher_id)
          }}
          >Finance</button>
          <button className="btn btn-primary"
          onClick={()=>{
            setTeacherGroups(row.groups)
            setTeacherGroupsModal(true)
            console.log(row.groups);
            setRowData(row)
            
          }}
          style={{
            margin:"0px 10px"
          }}
          >Groups</button>
           <button
              className="btn btn-danger"
              onClick={() => {
                setRowData(row);
  setOpenDeleteModal(true)
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              {deleteIcon}
            </button>
          </>
        );
      },
    },
  ]


  const TeacherGroupscolumns = [
    {
      key: "id",
      title: "#",
      dataIndex: "group_id",
      search: true,
    },
    {
      key: "name",
      title: "group name",
      dataIndex: "group_name",
      search: true,
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => {
        return (
          <>
       
           <button
              className="btn btn-danger"
              onClick={() => {
                setGroupRowData(row);
  setOpenDeleteTeacherGroupModal(true)
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              {deleteIcon}
            </button>
          </>
        );
      },
    },
    
  ]
  
  const getGroubs = async () => {
    axios
      .get("https://camp-coding.online/kidzania/admin/select_group.php")
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setGroups([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getGroubs();
  }, []);

  let group_options = groups.map((group) => {
    return { label: group.group_name, value: group.group_id };
  });



  const getTeacher = async () => {
    await axios.get("https://camp-coding.online/kidzania/admin/select_teacher_with_groups.php")
    .then((res) => {
      console.log(res);
  
      if (res.data.status == "success") {
        setTeachersData([...res.data.message]);
      } else if (res.data.status == "error") {
        toast.error(res.data.message);
      } else {
        toast.error("someThing went wrong");
      }
    })
    .catch((e) => console.log(e));
  }
  
    useEffect(() => {
      getTeacher();
    }, []);


    const handelUpdateTeacher = async() =>{
      let dataSend ={
        teacher_id:RowData.teacher_id,
        name : RowData.name,
        phone : RowData.phone,
        // group_ids : RowData.group_ids,



      }


       console.log(dataSend);
      
      await axios.post("https://camp-coding.online/kidzania/admin/update_teacher.php", JSON.stringify(dataSend))
      .then((res) => {
        console.log(res);
    
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setUpdateTeacherModal(false)
          getTeacher()
          
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
    }


    const getTeacherFinance = async (teacher_id) => {
      let dataSend ={
        teacher_id:teacher_id
      }
      axios
        .post("https://camp-coding.online/kidzania/admin/select_teacher_finance.php",JSON.stringify(dataSend))
        .then((res) => {
          console.log(res);
  
          if (res.data.status == "success") {
            setStudentsFinance([...res.data.message]);
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("someThing went wrong");
          }
        })
        .catch((e) => console.log(e));
    };


    const AddNewFinance = async () => {
      setLoading(true);
      let dataSend = {
        teacher_id: RowData?.teacher_id,
        price: NewFinanceData.price,
        describtion: NewFinanceData?.describtion,
      };
  
      console.log(dataSend);
  
      await axios
        .post(
          "https://camp-coding.online/kidzania/admin/add_teacher_finance.php",
          JSON.stringify(dataSend)
        )
        .then((res) => {
          console.log(res);
  
          if (res.data.status == "success") {
            toast.success(res.data.message);
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("someThing went wrong");
          }
        })
        .finally(() => {
          setLoading(false);
          setNewFinance(false);
          getTeacherFinance(RowData?.teacher_id);
        })
        .catch((e) => console.log(e));
    };
  
    

    const handelAddTeacher = async () => {
      setLoading(true);
      let dataSend = {
        name: AddNewTeacher.name,
      phone: AddNewTeacher.phone,
      group_ids: AddNewTeacher.group_ids,
      gender: AddNewTeacher.gender
      };
  
      console.log(dataSend);
  
      await axios
        .post(
          "https://camp-coding.online/kidzania/admin/add_teacher.php",
          JSON.stringify(dataSend)
        )
        .then((res) => {
          console.log(res);
  
          if (res.data.status == "success") {
            toast.success(res.data.message);
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("someThing went wrong");
          }
        })
        .finally(() => {
          setLoading(false);
          setOpenAddModal(false);
          getTeacher()
        })
        .catch((e) => console.log(e));
    };



    const deleteTeacher = async () => {
      setLoading(true);
      let dataSend = {
        teacher_id:RowData.teacher_id
      };
  
      console.log(dataSend);
  
      await axios
        .post(
          "https://camp-coding.online/kidzania/admin/delete_teacher.php",
          JSON.stringify(dataSend)
        )
        .then((res) => {
          console.log(res);
  
          if (res.data.status == "success") {
            toast.success(res.data.message);
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("someThing went wrong");
          }
        })
        .finally(() => {
          setLoading(false);
          setOpenDeleteModal(false);
          getTeacher()
        })
        .catch((e) => console.log(e));
    };


    const deleteTeacherFromGroup = async () => {
      console.log(RowData);
      
      setLoading(true);
      let dataSend = {
        teacher_id:RowData.teacher_id,
        group_id:GroupRowData.group_id,

      };
  
      return console.log(dataSend);
  
      await axios
        .post(
          "",
          JSON.stringify(dataSend)
        )
        .then((res) => {
          console.log(res);
  
          if (res.data.status == "success") {
            toast.success(res.data.message);
          } else if (res.data.status == "error") {
            toast.error(res.data.message);
          } else {
            toast.error("someThing went wrong");
          }
        })
        .finally(() => {
          setLoading(false);
          setOpenDeleteModal(false);
          getTeacher()
        })
        .catch((e) => console.log(e));
    };

  return (
    <div className="quiz">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Teacher</h1>
        <button
          className="btn btn-success"
          style={{
          backgroundColor:" #3c2365"

          }}  
          onClick={() => {
            setOpenAddModal(true);
          }}
        >
          Add Teacher
        </button>
      </div>
      <CustomTable dataSource={TeachersData} columns={Teachercolumns} />


      <Modal
        close={setUpdateTeacherModal}
        footer={false}
        title={"Update Teacher data"}
        visible={UpdateTeacherModal}
      >
        <span className="data_continer">
          <input
            type="text"
            placeholder="name..."
            defaultValue={RowData.name}
            onChange={(e) => {
              setRowData({
                ...RowData,
                name: e.target.value,
              });
            }}
          />
        </span>
        <span className="data_continer">
          <input
            type="number"
            placeholder="phone.."
            defaultValue={RowData?.phone}
            onChange={(e) => {
              console.log(RowData);
              
              setRowData({
                ...RowData,
                phone: e.target.value,
              });
            }}
          />
        </span>

        {/* <span className="data_continer">
          <Select
          isMulti
            defaultValue={{
              label: RowData.group_name,
              value: RowData.group_name,
            }}
            options={group_options}
            className="select"
            onChange={(e) => {
              console.log(e.value);
              

              
              setRowData({
                ...RowData,
                group_ids: e.value,
              });
            }}
          />
        </span> */}
        <button
          className="btn btn-success"
          style={{ float: "right" }}
          onClick={handelUpdateTeacher}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Update"}
        </button>
      </Modal>




      
      <Modal
        close={setFinance}
        footer={false}
        title={"Student Finance"}
        visible={Finance}
      >
        <div className="finance_data">
          <button
            style={{
              float: "right",
              background: "#3c2365",
              border: "none",
              padding: "5px",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: "123",
            }}
            onClick={() => {
              console.log("qdfwe");
              setNewFinance(true);
            }}
          >
            <img
              src={addFinanc}
              alt=""
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
            />
          </button>
          {studentsFinance && studentsFinance.length > 0 ? (
            <div>
              {studentsFinance.map((Finance, index) => (
                <div key={index} className="finance">
                  <span className="finance_discreption">
                    <img
                      src={
                        Finance.type === "uniform"
                          ? uniform
                          : Finance.type === "books"
                          ? book
                          : subscription
                      }
                      alt={Finance.type}
                    />
                    <span>
                      <p>{Finance.describtion}</p>
                      <p>{Finance.date}</p>
                    </span>
                  </span>

                  <p className="price" style={{cursor:"pointer"}}>{Finance.price + " " + "LE"}</p>
                </div>
              ))}
            </div>
          ) : (
            "There is no Finance"
          )}
        </div>
      </Modal>

      <Modal
        close={setNewFinance}
        footer={false}
        title={"New Finance"}
        visible={NewFinance}
      >
       
        {/* <span className="data_continer">
          <span className="data_name">dueDate</span>
          <span className="data">{RowData.due_date}</span>
        </span>

        <span className="data_continer">
          <span className="data_name">Subscription</span>
          <span className="data"></span>
        </span> */}

        <span className="data_continer">
          <input
            type="text"
            placeholder="Add Price..."
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                price: e.target.value,
              });
            }}
          />
        </span>

        <span className="data_continer">
          <input
            type="text"
            placeholder="describtion..."
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                describtion: e.target.value,
              });
            }}
          />
        </span>

        {/* <span className="data_continer">
          <Select
            className="select"
            options={FinanceOptions}
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                type: e.value,
              });
            }}
          />
        </span> */}

        <button
          className="btn btn-success"
          style={{ float: "right" }}
          onClick={AddNewFinance}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Add"}
        </button>
      </Modal>


      <Modal
        close={setTeacherGroupsModal}
        footer={false}
        title={"Teacher's group"}
        visible={TeacherGroupsModal}
      >
      <CustomTable dataSource={TeacherGroups} columns={TeacherGroupscolumns} />

        </Modal>


        
      <Modal
        close={setOpenAddModal}
        footer={false}
        title={"Add New Teacher"}
        visible={OpenAddModal}
      >
        <span className="data_continer">
          <input
            type="text"
            placeholder="name..."
            onChange={(e) => {
              setAddNewTeacher({
                ...AddNewTeacher,
                name:e.target.value
              });
            }}
          />
        </span>
        <span className="data_continer">
          <input
            type="number"
            placeholder="phone.."

            onChange={(e) => {
              setAddNewTeacher({
                ...AddNewTeacher,
                phone:e.target.value
              });
            }}
          />
        </span>

        <span className="data_continer">
        <Select
  isMulti
  defaultValue={{
    label: RowData.group_name,
    value: RowData.group_name,
  }}
  options={group_options}
  className="select"
  onChange={(selectedOptions) => {

    const selectedValues = selectedOptions.map((option) => option.value);
    
    setAddNewTeacher({
      ...AddNewTeacher,
      group_ids: selectedValues.join(","),
    });

    console.log(selectedValues);
  }}
/>
        </span>
        <span className="data_continer">
        <Select
  className="select"

options={genderOptions}
  onChange={(e)=>{
    setAddNewTeacher(
      {
        ...AddNewTeacher,
        gender:e.value
      }
    )
  }}
/>
        </span>
        <button
          className="btn btn-success"
          style={{ float: "right" }}
          onClick={handelAddTeacher}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Add"}
        </button>
      </Modal>

      <Modal
      close={setOpenDeleteModal}
      footer={false}
      title={"Delete Year"}
      visible={OpenDeleteModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this student from this Teacher?</h3>
        <p className="warning-message">
        This action cannot be undone. Please confirm that you want to delete
        the following:
        </p>
        <div className="year-details">
          <strong>Teacher:</strong> {RowData?.name}
        </div>
       
   
        
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteTeacher}
              >
                {loading ? <CircleLoader color="white" size={30}/> : "delete"}
              </button>
             
            
        
        
        
      </div>
    </Modal>


    <Modal
      close={setOpenDeleteTeacherGroupModal}
      footer={false}
      title={"Delete Teacher from group"}
      visible={OpenDeleteTeacherGroupModal}
    >
      <div className="delete-warning">
        <h3>Are you sure you want to delete this student from this Teacher from {GroupRowData?.group_name}?</h3>
        <p className="warning-message">
        This action cannot be undone. Please confirm that you want to delete
        the following:
        </p>
        <div className="year-details">
          
        </div>
       
   
        
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteTeacherFromGroup}
              >
                {loading ? <CircleLoader color="white" size={30}/> : "delete"}
              </button>
             
            
        
        
        
      </div>
    </Modal>
      <Toaster
  position="top-center"
  reverseOrder={false}
/>
    </div>
  );
}

export default DaysQuiz;
