import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { CircleLoader } from "react-spinners";
import { toast, Toaster } from "react-hot-toast";
import book from "../../assets/books.png";
import addFinanc from "../../assets/plus.png";
import calender from "../../assets/schedule.png";
import subscription from "../../assets/subscription.png";
import {
  deleteIcon
} from "../../assets/svgIcons";
import uniform from "../../assets/uniform.png";
import Loader from "../../components/loader";
import Modal from "../../components/modal";
import ChangeGroup from "../../components/students/changegroup";
import CustomTable from "../../components/table";
import Toast from "../../components/toast";
import AccordionComponents from "../years/students/AccordionComponents";
import "./style.css";

// ConfirmModal Component
function ConfirmModal({ visible, onClose, onConfirm, message }) {
  if (!visible) return null;

  return (
    <Modal
      close={onClose}
      footer={false}
      title={"تأكيد الإجراء"}
      visible={visible}
    >
      <div className="confirm-modal">
        <p>{message}</p>
        <div className="confirm-modal-buttons">
          <button className="btn btn-danger" onClick={onClose}>
            إلغاء
          </button>
          <button className="btn btn-success" onClick={onConfirm}>
            تأكيد
          </button>
        </div>
      </div>
    </Modal>
  );
}

function Students() {
  const FinanceOptions = [
    { label: "uniform", value: "uniform" },
    { label: "subscription", value: "subscription" },
    { label: "books", value: "books" },
  ];

  const Mdate = new Date().getMonth() + 1;
  const Ydate = new Date().getFullYear();
  const dailyreport = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];

  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [UpdateStudent, setUpdateStudent] = useState(false);
  const [AddStudentModal, setAddStudentModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [FirstStep, setFirstStep] = useState(true);
  const [SecondStep, setSecondStep] = useState(false);
  const [ThirdStep, setThirdStep] = useState(false);
  const [FourthStep, setFourthStep] = useState(false);

  const [StudentReports, setStudentReports] = useState(false);
  const [DailyReports, setDailyReports] = useState(false);
  const [MonthlyReports, setMonthlyReports] = useState(false);
  const [ReportMonthesModal, setReportMonthesModal] = useState(false);
  const [ReportMonthes, setReportMonthes] = useState([]);
  const [MonthlyReportsData, setMonthlyReportsData] = useState([]);

  const [openChangeGroup, setOpenChangeGroup] = useState(false);
  const [students, setStudents] = useState(null);
  const [groups, setGroups] = useState([]);
  const [NewStudentData, setNewStudentData] = useState({
    name: null,
    sub_price: null,
    due_date: null,
    nationality: null,
    date_of_birth: null,
    num_of_brother: null,
    arrange_child_them: null,
    address: null,
    date_of_addmision: null,
    gender: null,
    group_id: null,
    mother_academic_qualification: null,
    mother_job: null,
    mother_phone: null,
    mother_name: null,
    father_academic_qualification: null,
    father_job: null,
    father_phone: null,
    attendance_time: null,
    depart_time: null,
    kids_transportation: null,
    people_allowed_to_recive: null,
    relative_relation: null,
    national_id: null,
    sleeping_hours: null,
    fav_food: null,
    fav_carton_char: null,
    chronic_deases: null,
    type_of_allergy: null,
    best_personalty_trail: null,
    most_undesirable: null,
    fare_of_certin: null,
    method_punshment: null,
    reward_style: null,
    notes: null,
  });

  const [AllData, setAllData] = useState(false);
  const [Finance, setFinance] = useState(false);
  const [NewFinance, setNewFinance] = useState(false);
  const [NewFinanceData, setNewFinanceData] = useState({
    student_id: null,
    date: null,
    price: null,
    describtion: null,
    type: null,
  });

  const [studentsFinance, setStudentsFinance] = useState(null);

  const [RowData, setRowData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    centerType: "",
    gen_id: "",
    group_id: "",
    package_id: "",
  });
  const [confirmAction, setConfirmAction] = useState({
    visible: false,
    message: "",
    onConfirm: null,
  }); // State for confirmation modal

  const getStudents = async () => {
    try {
      const response = await fetch(
        "https://camp-coding.online/kidzania/admin/select_all_students.php"
      );
      const data = await response.json();
      console.log(response);

      setStudents(data.message);
      console.log(data);
    } catch (err) {
      setStudents([]);
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  const getGroubs = async () => {
    axios
      .get("https://camp-coding.online/kidzania/admin/select_group.php")
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setGroups([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getGroubs();
  }, []);

  let group_options = groups.map((group) => {
    return { label: group.group_name, value: group.group_id };
  });

  console.log(group_options);

  const getStudentFinance = async (student_id) => {
    let dataSend = {
      student_id: student_id,
    };

    console.log(dataSend);

    await axios
      .post(
        "https://camp-coding.online/kidzania/admin/select_student_finance.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          setStudentsFinance([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  const AddNewFinance = async () => {
    setLoading(true);
    let dataSend = {
      student_id: RowData?.student_id,
      date: RowData?.due_date,
      price: NewFinanceData.price,
      describtion: NewFinanceData?.describtion,
      type: NewFinanceData?.type,
    };

    console.log(dataSend);

    await axios
      .post(
        "https://camp-coding.online/kidzania/admin/add_finance.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          toast.succcess(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
        setNewFinance(false);
        getStudentFinance(RowData?.student_id);
      })
      .catch((e) => console.log(e));
  };

  const handelUpdateStudent = async () => {
    setLoading(true);
    let dataSend = {
      student_id: RowData?.student_id,
      name: RowData?.name,
      group_id: RowData?.group_id,
      father_phone: RowData?.father_phone,
    };

    console.log(dataSend);

    await axios
      .post(
        "https://camp-coding.online/kidzania/admin/update_student.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status == "success") {
          toast.succcess(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
        setUpdateStudent(false);
        getStudents();
      })
      .catch((e) => console.log(e));
  };

  // const handelAddStudent= async()=>{

  //   setLoading(true)

  //   let dataSend = {
  //     name: NewStudentData.name,
  //     sub_price:  NewStudentData.sub_price,
  //     due_date: NewStudentData.due_date,
  //     nationality: NewStudentData.nationality,
  //     date_of_birth: NewStudentData.date_of_birth,
  //     num_of_brother: NewStudentData.num_of_brother,
  //     arrange_child_them: NewStudentData.arrange_child_them,
  //     address: NewStudentData.address,
  //     date_of_addmision: NewStudentData.date_of_addmision,
  //     gender: NewStudentData.gender,
  //     group_id: NewStudentData.group_id,
  //     mother_academic_qualification: NewStudentData.mother_academic_qualification,
  //     mother_job: NewStudentData.mother_job,
  //     mother_phone: NewStudentData.mother_phone,
  //     mother_name :NewStudentData.mother_name,
  //     father_academic_qualification :NewStudentData.father_academic_qualification,
  //     father_job :NewStudentData.father_job,
  //     father_phone :NewStudentData.father_phone,
  //     attendance_time :NewStudentData.attendance_time,
  //     depart_time  :NewStudentData.depart_time,
  //     kids_transportation :NewStudentData.kids_transportation,
  //     people_allowed_to_recive :NewStudentData.people_allowed_to_recive,
  //     relative_relation :NewStudentData.relative_relation,
  //     national_id :NewStudentData.national_id,
  //     sleeping_hours:NewStudentData.sleeping_hours,
  //     fav_food:NewStudentData.fav_food,
  //     fav_carton_char :NewStudentData.fav_carton_char,
  //     chronic_deases:NewStudentData.chronic_deases,
  //     type_of_allergy :NewStudentData.type_of_allergy,
  //     best_personalty_trail:NewStudentData.best_personalty_trail,
  //     most_undesirable:NewStudentData.most_undesirable,
  //     fare_of_certin :NewStudentData.fare_of_certin,
  //     method_punshment :NewStudentData.method_punshment,
  //     reward_style :NewStudentData.reward_style,
  //     notes :NewStudentData.notes,
  //   }

  //   console.log(dataSend);

  //   await axios.post(
  //     "https://camp-coding.online/kidzania/admin/add_student.php",
  //     JSON.stringify(dataSend)
  //   ).then(res=>{
  //     if(res.data.status == "success"){
  //       toast.succcess(res.data.message)

  //     }else if(res.data.status == "error"){
  //       toast.succcess(res.data.message)

  //     }else{
  //       toast.succcess("someThing went wrong")

  //     }
  //   }).finally(()=>{
  //     setLoading(false)
  //     setAddStudentModal(false)
  //     getStudents()
  //   }).catch(e=>console.log(e))

  // }

  const getStudentsMonthes = async () => {
    axios
      .get("https://camp-coding.online/kidzania/admin/select_months.php")
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "success") {
          setReportMonthes([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };
  const [monthIdReport, setMonthIdReport] = useState(null);
  const uniqueMonths = ReportMonthes;

  const getStudentsMonthlyReports = async (MonthId) => {
    let dataSend = {
      student_id: RowData.student_id,
      month_id: MonthId,
    };
    console.log(dataSend);

    axios
      .post(
        "https://camp-coding.online/kidzania/admin/select_monthly_reports.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setMonthlyReportsData([...res.data.message]);
        } else if (res.data.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .catch((e) => console.log(e));
  };

  const deleteStudent = async () => {
    setLoading(true);
    let dataSend = {
      student_id: RowData.student_id,
    };

    console.log(dataSend);
    axios
      .post(
        "https://camp-coding.online/kidzania/admin/delete_student.php",
        JSON.stringify(dataSend)
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "success") {
          toast.success(res.data.message);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("someThing went wrong");
        }
      })
      .finally(() => {
        getStudents();
        setOpenDeleteModal(false);
      })
      .catch((e) => console.log(e));
  };

  const [sendMessage, setSendMessage] = useState(false);
  const [phone, setPhone] = useState(true);
  const [logoutModal , setLogoutModal] = useState(false);
  const [messageText, setMessageText] = useState(true);
  const [sendMessageLoading, setSendMessageLoading] = useState(null);
  const sendMessages = async () => {
    setSendMessageLoading(true);
    await axios
      .post(
        `https://app.wawp.net/api/send`,
        JSON.stringify({
          number: "2" + phone?.father_phone,
          type: "link",
          enable: true,
          message: messageText,
          instance_id: "6721FE622F9ED",
          access_token: "671f9975a6bd9",
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(async (res) => {
        await axios
          .post(
            `https://app.wawp.net/api/send`,
            JSON.stringify({
              number: "2" + phone?.mother_phone,
              type: "link",
              enable: true,
              message: messageText,
              instance_id: "6721FE622F9ED",
              access_token: "671f9975a6bd9",
            }),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((res) => {
            if (res?.data?.status == "success") {
              setToast({ type: "success", message: "تم الارسال بنجاح" });

              setSendMessage(false);
            } else {
              toast.error("حدث خطأ أثناء إرسال الرسالة");
            }
          })
          .catch((err) => err)
          .finally(() => setSendMessageLoading(false));
      });
  };
  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "student_id",
      search: true,
    },
    {
      key: "name",
      title: "Student name",
      dataIndex: "name",
      search: true,
    },
    {
      key: "group_name",
      title: "group name",
      dataIndex: "group_name",
      render: (e, row) => {
        return <span>{row?.group_name}</span>;
      },
    },
    {
      key: "phone",
      title: "phone",
      dataIndex: "father_phone",
      render: (e, row) => {
        return <span>{row?.father_phone}</span>;
      },
    },
    {
      key: "actions",
      title: "actions",
      dataIndex: "x",
      render: (e, row) => {
        return (
          <div className="rowDiv">
            <button
              className="btn btn-danger"
              style={{
                color: "white",
              }}
              onClick={() => {
                setAllData(true);
                setRowData(row);
              }}
            >
              show all data
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                setUpdateStudent(true);
                setRowData(row);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              update
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                setSendMessage(true);
                setPhone(row);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              Send Message
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setFinance(true);
                getStudentFinance(row.student_id);
                setRowData(row);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              finance
            </button>

            <button
              className="btn btn-warning"
              onClick={() => {
                setRowData(row);
                setStudentReports(true);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              Reports
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                setRowData(row);
                setOpenDeleteModal(true);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              {deleteIcon}
            </button>

            <button
              className="btn btn-danger"
              onClick={() => {
                setRowData(row);
                setLogoutModal(true);
              }}
              style={{
                margin: "0 5px",
                color: "white",
              }}
            >
              Logout
            </button>
          </div>
        );
      },
    },
  ];

  function handleLogout() {
    const data_send = {
      student_id : RowData?.student_id
    }

    axios.post('https://camp-coding.online/kidzania/admin/student_logout.php',data_send)
    .then(res=> {
      if(res?.data?.status == "success") {
        setToast({type:"success",message:res?.data?.message})
        toast.success(res?.data?.message)
      }else {
        toast.error(res?.data?.message || "There's a problem");
      }
    }).catch(e => console.log(e))
    .finally(() => setLogoutModal(false))
  }

  return (
    <div className="students">
      <div className="tablePageHeader">
        <h1 className="pageTitle">students</h1>
      </div>
      {/* <button
        className="btn btn-success"
        style={{ margin: "10px 0" }}
        onClick={() => setAddStudentModal(true)}
      >
        Add Student
      </button> */}
      <CustomTable dataSource={students} columns={columns} />
      <ChangeGroup
        userData={openChangeGroup}
        openModal={openChangeGroup}
        setOpenModal={setOpenChangeGroup}
      />

      <Modal
        close={setLogoutModal}
        footer={false}
        title={"Delete Student"}
        visible={logoutModal}
      >
        <div className="delete-warning">
          <h3>Are you sure you want to logout this student?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following:
          </p>
          <div className="year-details">
            <strong>student:</strong> {RowData?.name}
          </div>

          <button
            type="button"
            className="btn btn-danger"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </Modal>


      {/* Render Add Subscription Form if a student is selected */}
      <Modal
        close={setAllData}
        footer={false}
        title={"All student data"}
        visible={AllData}
      >
        <div>
          <div className="data_title">
            <p>Student data</p>
          </div>
          <div className="Student_data">
            <span className="data_continer">
              <span className="data_name">name</span>
              <span className="data">{RowData.name}</span>
            </span>
            <span className="data_continer">
              <span className="data_name">Nationality</span>
              <span className="data">{RowData.nationality}</span>
            </span>
            <span className="data_continer">
              <span className="data_name">Date of birth</span>
              <span className="data">{RowData.date_of_birth}</span>
            </span>
            <span className="data_continer">
              <span className="data_name">num of brother</span>
              <span className="data">{RowData.num_of_brother}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">Address</span>
              <span className="data">{RowData.address}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">Gender</span>
              <span className="data">{RowData.gender}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">group name</span>
              <span className="data">{RowData.group_name}</span>
            </span>
          </div>
          <div className="data_title">
            <p>Father data</p>
          </div>

          <div className="Student_data">
            <span className="data_continer">
              <span className="data_name">Academic qualification</span>
              <span className="data">
                {RowData.father_academic_qualification}
              </span>
            </span>

            <span className="data_continer">
              <span className="data_name">Job</span>
              <span className="data">{RowData.father_job}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">Phone number</span>
              <span className="data">{RowData.father_phone}</span>
            </span>
          </div>

          <div className="data_title">
            <p>Mother data</p>
          </div>

          <div className="Student_data">
            <span className="data_continer">
              <span className="data_name">mother name</span>
              <span className="data">{RowData.mother_name}</span>
            </span>

            <span className="data_continer">
              <span className="data_name"> Academic qualification</span>
              <span className="data">
                {RowData.mother_academic_qualification}
              </span>
            </span>

            <span className="data_continer">
              <span className="data_name">Job</span>
              <span className="data">{RowData.mother_job}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">Mobile number</span>
              <span className="data">{RowData.mother_phone}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">attendance time</span>
              <span className="data">{RowData.attendance_time}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">Depart time</span>
              <span className="data">{RowData.depart_time}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">people allowed to recive</span>
              <span className="data">{RowData.people_allowed_to_recive}</span>
            </span>

            <span className="data_continer">
              <span className="data_name">relative relation</span>
              <span className="data">{RowData.relative_relation}</span>
            </span>
          </div>
        </div>
      </Modal>

      <Modal
        close={setFinance}
        footer={false}
        title={"Student Finance"}
        visible={Finance}
      >
        <div className="finance_data">
          <button
            style={{
              float: "right",
              background: "#3c2365",
              border: "none",
              padding: "5px",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: "123",
            }}
            onClick={() => {
              console.log("qdfwe");
              setNewFinance(true);
            }}
          >
            <img
              src={addFinanc}
              alt=""
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
            />
          </button>
          {studentsFinance && studentsFinance.length > 0 ? (
            <div>
              {studentsFinance.map((Finance, index) => (
                <div key={index} className="finance">
                  <span className="finance_discreption">
                    <img
                      src={
                        Finance.type === "uniform"
                          ? uniform
                          : Finance.type === "books"
                          ? book
                          : subscription
                      }
                      alt={Finance.type}
                    />
                    <span>
                      <p>{Finance.type}</p>
                      <p>{Finance.date}</p>
                    </span>
                  </span>

                  <p className="price">{Finance.price + " " + "LE"}</p>
                </div>
              ))}
            </div>
          ) : (
            "There is no Finance"
          )}
        </div>
      </Modal>

      <Modal
        close={setNewFinance}
        footer={false}
        title={"New Finance"}
        visible={NewFinance}
      >
        <span className="data_continer">
          <span className="data_name">dueDate</span>
          <span className="data">{RowData.due_date}</span>
        </span>

        <span className="data_continer">
          <span className="data_name">Subscription</span>
          <span className="data"></span>
        </span>

        <span className="data_continer">
          <input
            type="text"
            placeholder="Add Price..."
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                price: e.target.value,
              });
            }}
          />
        </span>

        <span className="data_continer">
          <input
            type="text"
            placeholder="Payment Details..."
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                describtion: e.target.value,
              });
            }}
          />
        </span>

        <span className="data_continer">
          <Select
            className="select"
            options={FinanceOptions}
            onChange={(e) => {
              setNewFinanceData({
                ...NewFinanceData,
                type: e.value,
              });
            }}
          />
        </span>

        <button
          className="btn btn-success"
          style={{ float: "right" }}
          onClick={AddNewFinance}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Add"}
        </button>
      </Modal>

      <Modal
        close={setUpdateStudent}
        footer={false}
        title={"Update Student data"}
        visible={UpdateStudent}
      >
        <span className="data_continer">
          <input
            type="text"
            placeholder="name..."
            defaultValue={RowData.name}
            onChange={(e) => {
              setRowData({
                ...RowData,
                name: e.target.value,
              });
            }}
          />
        </span>
        <span className="data_continer">
          <input
            type="number"
            placeholder="phone.."
            defaultValue={RowData?.father_phone}
            onChange={(e) => {
              setRowData({
                ...RowData,
                father_phone: e.target.value,
              });
            }}
          />
        </span>

        <span className="data_continer">
          <Select
            defaultValue={{
              label: RowData.group_name,
              value: RowData.group_name,
            }}
            options={group_options}
            className="select"
            onChange={(e) => {
              setRowData({
                ...RowData,
                group_id: e.value,
              });
            }}
          />
        </span>
        <button
          className="btn btn-success"
          style={{ float: "right" }}
          onClick={handelUpdateStudent}
        >
          {loading ? <CircleLoader color="white" size={30} /> : "Update"}
        </button>
      </Modal>
      <Modal
        close={setOpenDeleteModal}
        footer={false}
        title={"Delete Year"}
        visible={openDeleteModal}
      >
        <div className="delete-warning">
          <h3>Are you sure you want to delete this student?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following:
          </p>
          <div className="year-details">
            <strong>student:</strong> {RowData?.name}
          </div>

          <button
            type="button"
            className="btn btn-danger"
            onClick={deleteStudent}
          >
            {loading ? <CircleLoader color="white" size={30} /> : "delete"}
          </button>
        </div>
      </Modal>

      <Modal
        close={setStudentReports}
        footer={false}
        title={"Student Reports"}
        visible={StudentReports}
      >
        <div
          className="daily_reports"
          onClick={() => {
            setDailyReports(true);
          }}
        >
          <span className="daily"></span>
          <h3>Daily Report</h3>
        </div>
        <div
          className="daily_reports"
          onClick={() => {
            setReportMonthesModal(true);
            getStudentsMonthes();
          }}
        >
          <span className="monthly"></span>
          <h3>Monthly Report</h3>
        </div>
      </Modal>

      <Modal
        close={setDailyReports}
        footer={false}
        title={"Student daily Reports"}
        visible={DailyReports}
      >
        <div className="data_title">
          <h4>
            {Ydate}-{Mdate}
          </h4>
        </div>
        <div className="days">
          {dailyreport.map((day, index) => {
            return (
              <p key={index} className="day">
                {day}
              </p>
            );
          })}
        </div>
      </Modal>

      <Modal
        close={setReportMonthesModal}
        footer={false}
        title={"Student report's Monthes"}
        visible={ReportMonthesModal}
      >
        {uniqueMonths.map((month, index) => (
          <div key={index}>
            <p
              className="month"
              onClick={() => {
                setMonthlyReports(true);
                console.log(month);
                getStudentsMonthlyReports(month.date_id);
                setMonthIdReport(month.date_id);
              }}
            >
              <img
                src={calender}
                alt=""
                style={{ width: "20px", height: "20px", margin: "0px 10px" }}
              />
              {month.month}
            </p>
          </div>
        ))}
      </Modal>

      <Modal
        close={setMonthlyReports}
        footer={false}
        title={"Student Monthly Reports"}
        visible={MonthlyReports}
      >
        <AccordionComponents
          monthIdReport={monthIdReport}
          reports={MonthlyReportsData}
          StudentId={RowData}
        />
      </Modal>

      <Modal
        close={setSendMessage}
        footer={false}
        title={"Send Message"}
        visible={sendMessage}
      >
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          style={{ width: "100%" }}
          onChange={(e) => setMessageText(e.target.value)}
        ></textarea>
        <br />
        {sendMessageLoading ? (
          <Loader />
        ) : (
          <button onClick={() => sendMessages()} className="btn btn-success">
            Send Message
          </button>
        )}
      </Modal>

      {/* Confirm Modal */}
      <ConfirmModal
        visible={confirmAction.visible}
        message={confirmAction.message}
        onClose={() => setConfirmAction({ ...confirmAction, visible: false })}
        onConfirm={() => {
          confirmAction.onConfirm();
          setConfirmAction({ ...confirmAction, visible: false });
        }}
      />

      {toast && (
        <Toast
          message={toast?.message}
          type={toast?.type}
          onClose={() => setToast(false)}
        />
      )}

<Toaster position="top-center" reverseOrder={false} />
    </div>
  );
}

export default Students;
